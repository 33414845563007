function rearrangeElements() {
  if ($(window).width() < 550) {
    $('.pre-header').before($('.header'));
  } else {
    $('.header').before($('.pre-header'));
  }
}

$(window).resize(rearrangeElements);
rearrangeElements();
